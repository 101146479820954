import { useAuthenticationToken } from '../common/hooks/use-authentication-token'

/**
 * Used together with <Route> for all destinations that should require log in
 * <Route path="/jobs" element={<PrivateRoute Component={ListJobs} />} />
 */
export function PrivateRoute({
  Component,
  locationReplaceFunction = window.location.replace.bind(window.location),
  storage,
}: {
  readonly Component: () => React.JSX.Element
  readonly locationReplaceFunction?: (typeof window.location)['replace']
  readonly storage?: Storage
}) {
  const { isAuthenticated } = useAuthenticationToken(storage)

  if (!isAuthenticated) {
    // TODO: For SSO login, stash the desired location in 'state' param
    // https://github.com/qcom-ai-hub/tetracode/issues/8638
    // let loc = useLocation()
    locationReplaceFunction('/accounts/sso_login/')
  }

  return <Component />
}
